<template>
  <div>
    <h3>Qual o grupo da solicitação?</h3>
    <v-row v-if="loading">
      <v-skeleton-loader type="actions"></v-skeleton-loader>
    </v-row>
    <v-row v-else>
      <div class="text-center" v-for="group in groups.data" :key="group.id">
        <v-btn color="primary" class="ma-2" @click="goToCategory(group.id)">
          <v-icon left v-if="group.icon">{{ group.icon }}</v-icon>
          {{ group.name }}
        </v-btn>
      </div>
      <v-alert
        v-if="!loading && !groups.data.length"
        border="left"
        dense
        colored-border
        type="warning"
        class="ma-5"
        >Nenhum grupo encontrado!
      </v-alert>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState("solicitationType", ["groups"])
  },
  methods: {
    ...mapActions("solicitationType", ["loadGroups"]),
    goToCategory(id_group) {
      this.$router.push({
        name: "solicitation-category",
        params: { id: id_group }
      });
    }
  },
  async mounted() {
    await this.loadGroups().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  }
};
</script>
<style scoped></style>
